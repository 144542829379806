@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
        font-family: "Montserrat", sans-serif;
        color: #606060;
    }
}

@layer components {
    .btn-primary {
        @apply py-3 px-4 bg-buttonPrimary text-white font-bold rounded-md hover:bg-buttonHover md:text-xs text-[10px] uppercase;
    }

    .btn-login {
        @apply py-3 px-4 bg-buttonPrimary text-white font-bold rounded-md hover:bg-buttonHover md:text-xs text-[10px] uppercase w-[248px] md:w-[270px];
    }

    .btn-search {
        @apply py-2.5 px-6 bg-buttonPrimary rounded-r-md hover:bg-buttonHover md:text-xs text-[10px] border border-brandPrimary;
    }
}

.navBar button {
    z-index: 10;
    cursor: pointer;
}

.menuNav {
    overflow-y: scroll;
    list-style: none;
    position: fixed;
    top: 61px;
    background: white;
    left: 0;
    bottom: 0;
    height: 100vh;
    width: 0;
    z-index: 9;
}

.menuNav.showMenu {
    width: 100%;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./scss/fonts/Montserrat-Bold.woff2') format('woff2'),
    url('./scss/fonts/Montserrat-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./scss/fonts/Montserrat-Black.woff2') format('woff2'),
    url('./scss/fonts/Montserrat-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Font Awesome 6 Free';
    src: url('./scss/fonts/FontAwesome6Free-Regular.woff2') format('woff2'),
    url('./scss/fonts/FontAwesome6Free-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./scss/fonts/Montserrat-ExtraLight.woff2') format('woff2'),
    url('./scss/fonts/Montserrat-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat Medium';
    src: url('./scss/fonts/Montserrat-Medium.woff2') format('woff2'),
    url('./scss/fonts/Montserrat-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./scss/fonts/Montserrat-BlackItalic.woff2') format('woff2'),
    url('./scss/fonts/Montserrat-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./scss/fonts/Montserrat-BoldItalic.woff2') format('woff2'),
    url('./scss/fonts/Montserrat-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./scss/fonts/Montserrat-ExtraBold.woff2') format('woff2'),
    url('./scss/fonts/Montserrat-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./scss/fonts/Montserrat-ExtraBoldItalic.woff2') format('woff2'),
    url('./scss/fonts/Montserrat-ExtraBoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./scss/fonts/Montserrat-LightItalic.woff2') format('woff2'),
    url('./scss/fonts/Montserrat-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./scss/fonts/Montserrat-Light.woff2') format('woff2'),
    url('./scss/fonts/Montserrat-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Font Awesome 6 Free';
    src: url('./scss/fonts/FontAwesome6Free-Solid.woff2') format('woff2'),
    url('./scss/fonts/FontAwesome6Free-Solid.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./scss/fonts/Montserrat-Italic.woff2') format('woff2'),
    url('./scss/fonts/Montserrat-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./scss/fonts/Montserrat-MediumItalic.woff2') format('woff2'),
    url('./scss/fonts/Montserrat-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./scss/fonts/Montserrat-ExtraLightItalic.woff2') format('woff2'),
    url('./scss/fonts/Montserrat-ExtraLightItalic.woff') format('woff');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./scss/fonts/Montserrat-Thin.woff2') format('woff2'),
    url('./scss/fonts/Montserrat-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./scss/fonts/Montserrat-Regular.woff2') format('woff2'),
    url('./scss/fonts/Montserrat-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./scss/fonts/Montserrat-SemiBoldItalic.woff2') format('woff2'),
    url('./scss/fonts/Montserrat-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./scss/fonts/Montserrat-SemiBold.woff2') format('woff2'),
    url('./scss/fonts/Montserrat-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./scss/fonts/Montserrat-ThinItalic.woff2') format('woff2'),
    url('./scss/fonts/Montserrat-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

